<template>
  <div class="home">
    <!-- banner图片 -->
    <div class="banner">
      <div class="bannerTxt">智慧酒店·五星体验打造专家</div>
      <div class="bannerText">BOT智能科技多年的技术积累，倾尽全力打造出高稳定性的无线化智能酒店解决方案，</div>
      <div class="bannerTexts">并始终保持高可定制化能力。</div>
    </div>
    <!-- 超级主机 -->
    <!-- <div class="container">
      <div class="gateway">
        <div class="gateLeft">
          <div class="gate1">超级主机</div>
          <div class="gate2">一个主机，改变一切</div>
          <div class="gate3">
            <p>强大的系统硬件，集不同设备和功能于一身。</p>
            <p>更有优秀稳定的配套软硬件解决方案，让您拥有舒适，自然的入住体验。</p>
          </div>
          <router-link to="/pc/ProGateway" class="more">
            <p class="more_txt">了解更多</p>
          </router-link>
        </div>
        <div class="gateRight">
          <img src="../../assets/index/gateway.png" alt />
        </div>
      </div>
      <div class="cirBox">
        <div class="cir">
          <div class="circle cirOne">
            <div class="circle-left"></div>
            <div class="circle-right"></div>
            <div class="circle-text">
              <div>无线设备</div>
              <div>控制</div>
            </div>
          </div>
        </div>
        <div class="cir">
          <div class="circle cirTwo">
            <div class="circle-left"></div>
            <div class="circle-right"></div>
            <div class="circle-text">
              <div>云端互联</div>
              <div>不掉线</div>
            </div>
          </div>
        </div>
        <div class="cir">
          <div class="circle cirThree">
            <div class="circle-left"></div>
            <div class="circle-right"></div>
            <div class="circle-text">
              <div>自由接入</div>
              <div>完全定制</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="certification">
       <div class="ctrlHead">
        <div class="title">
          <div>资质认证</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="certiBox">
        <div class="certiImg"></div>
      </div>
    </div>
    <div class="certification">
       <div class="ctrlHead">
        <div class="title">
          <div>全屋智能</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="certiBox">
        <Whole></Whole>
      </div>
    </div>
    <!-- BOT客控 -->
    <div class="control">
      <div class="ctrlHead">
        <div class="title">
          <div>BOT客控系统</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="ctrlBox">
        <div class="tx">全新的 UI 美学，简单而符合直觉的界面，在提升效率的同时又不失掌控。为用户打造完美的人性化智能设备体验。</div>
        <div class="ctrlImg"></div>
      </div>
    </div>
    <!-- 图片组 -->
    <!-- <div class="imgBox">
      <div class="imgUlOne">
        <router-link to="/pc/ProGateway" class="liImg">
          <div class="mask"></div>
          <div class="name">超级主机</div>
          <div class="demoMore">
            了解更多
            <span></span>
          </div>
        </router-link>
      </div>
      <div class="imgUlTwo">
        <div class="twoTop">
          <router-link to="/pc/ProUsb" class="liImg">
            <div class="mask"></div>
            <div class="name">五孔带USB电源面板</div>
            <div class="demoMore">
              了解更多
              <span></span>
            </div>
          </router-link>
          <router-link to="/pc/ProSwitch" class="liImg">
            <div class="mask"></div>
            <div class="name">智能开关</div>
            <div class="demoMore">
              了解更多
              <span></span>
            </div>
          </router-link>
        </div>

        <div class="twoTwo">
          <router-link to="/pc/ProCurtain" class="liImg">
            <div class="mask"></div>
            <div class="name">窗帘电机</div>
            <div class="demoMore">
              了解更多
              <span></span>
            </div>
          </router-link>
        </div>
      </div>
    </div> -->
    <!-- 定制/案例 -->
    <div class="demoBox">
      <router-link to="/pc/custom" class="demo">
        <div class="demoTit">智能定制</div>
        <div class="demoTxt">快速获得只属于你的专属方案。</div>
        <div class="demoMore">
          了解更多
          <span></span>
        </div>
      </router-link>
      <router-link to="/pc/demo" class="demo">
        <div class="demoTit">精选案例</div>
        <div class="demoTxt">查看我们为客户打造的专属解决方案。</div>
        <div class="demoMore">
          了解更多
          <span></span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Whole from './Whole.vue';
export default {
  components:{
    Whole
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 8.4rem;
  background: url("../../assets/index/banner.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: 400;
  color: #333;
  .bannerTxt {
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
    margin-top: 1.4rem;
  }
  .bannerText {
    margin-top: 0.24rem;
  }
  .bannerTexts {
    margin-top: 0.16rem;
  }
}
// 了解更多
.more {
  width: 2.4rem;
  height: 0.6rem;
  border-radius: 0.06rem;
  margin-top: 0.8rem;
  border: 1px solid #ff9000;
  text-align: center;
  cursor: pointer;
  font-size: 0.16rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .more_txt {
    position: relative;
    color: #ff9000;
    z-index: 1;
  }
  &:hover {
    .more_txt {
      color: #fff;
    }
  }
  &::before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #ff9000;
    transition: all 0.3s;
    width: 0%;
  }

  &:hover::before {
    width: 100%;
  }
}
// 超级主机
.container {
  width: 12rem;
  margin: 0 auto;
  padding-top: 1.44rem;
  position: relative;
  .gateway {
    width: 100%;
    display: flex;
    overflow: hidden;
    text-align: left;
    .gateLeft {
      margin-top: 0.95rem;
      .gate1 {
        font-size: 0.36rem;
        font-weight: bold;
        color: #333;
      }
      .gate2 {
        font-size: 0.24rem;
        line-height: 0.24rem;
        margin-top: 0.19rem;
        margin-bottom: 0.58rem;
        font-weight: bold;
        color: #333;
      }
      .gate3 {
        font-size: 0.18rem;
        font-weight: 400;
        color: #999;
      }
    }
    .gateRight {
      width: 2.72rem;
      height: 5.11rem;
      margin-left: 2.42rem;
      img {
        width: 2.72rem;
        height: 5.11rem;
      }
    }
  }
  // 圆
  .cirBox {
    width: 100%;
    margin-top: 0.74rem;
    display: flex;
    justify-content: space-evenly;
    z-index: -99;
    .cir {
      .circle {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        margin: 0 auto;
        .circle-left,
        .circle-right {
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          border: 0.08rem solid #ff9000;
          z-index: 3;
        }
        .circle-text {
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          color: #fff;
          font-size: 0.2rem;
          border: 0.08rem solid #f3f3f3;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          div {
            font-size: 0.18rem;
            color: #666666;
            &:first-of-type {
              margin-bottom: 0.13rem;
            }
          }
        }
      }
      .cirOne {
        .circle-left {
          clip-path: polygon(0% 0, 50% 0%, 50% 100%, 0 100%);
          transform: rotate(-54deg);
          -webkit-transform: rotate(-54deg);
          -moz-transform: rotate(-54deg);
          -ms-transform: rotate(-54deg);
          -o-transform: rotate(-54deg);
        }
        .circle-right {
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%);
        }
      }
      .cirTwo {
        .circle-left {
          clip-path: polygon(0% 0, 50% 0%, 50% 100%, 0 100%);
          transform: rotate(7deg);
          -webkit-transform: rotate(7deg);
          -moz-transform: rotate(7deg);
          -ms-transform: rotate(7deg);
          -o-transform: rotate(7deg);
        }
        .circle-right {
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%);
          transform: rotate(58deg);
          -webkit-transform: rotate(58deg);
          -moz-transform: rotate(58deg);
          -ms-transform: rotate(58deg);
          -o-transform: rotate(58deg);
        }
      }
      .cirThree {
        .circle-left {
          clip-path: polygon(0% 0, 50% 0%, 50% 100%, 0 100%);
          transform: rotate(-15deg);
          -webkit-transform: rotate(-15deg);
          -moz-transform: rotate(-15deg);
          -ms-transform: rotate(-15deg);
          -o-transform: rotate(-15deg);
        }
        .circle-right {
          clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%);
          transform: rotate(-66deg);
          -webkit-transform: rotate(-66deg);
          -moz-transform: rotate(-66deg);
          -ms-transform: rotate(-66deg);
          -o-transform: rotate(-66deg);
        }
      }
    }
  }
}

.certification{
  width: 100%;
  margin-top: 0.4rem;
  

  .certiBox{
    width: 12rem;
    margin: 0 auto;
    .certiImg {
      background: url("../../assets/index/item1.png");
      background-size: 100% 100%;
      margin-top: 0.2rem;
      width: 100%;
      height: 6rem;
    }
  }
}

.ctrlHead {
    width: 100%;
    height: 0.57rem;
    // box-shadow: 0px 0.12rem 0.08rem -0.12rem rgba(0, 0, 0, 0.1);
    .title {
      width: 12rem;
      margin: 0 auto;
      text-align: left;
      font-size: 0.36rem;
      font-weight: bold;
      color: #333333;
      position: relative;
      .line {
        position: absolute;
        top: 0.53rem;
        left: 0px;
        width: 0.4rem;
        height: 0.04rem;
        background: #ff9000;
      }
    }
  }
// BOT客控
.control {
  width: 100%;
  margin-top: 0.4rem;
 
  .ctrlBox {
    width: 12rem;
    margin: 0 auto;
    margin-top: 0.2rem;
    text-align: left;
    .tx {
      font-size: 0.18rem;
      color: #999999;
    }

    .ctrlImg {
      background: url("../../assets/index/bot.png");
      background-size: 100% 100%;
      margin-top: 0.2rem;
      width: 100%;
      height: 6rem;
    }
  }
}
// 图片组
.imgBox {
  width: 12rem;
  margin: 0 auto;
  margin-top: 1.2rem;
  display: flex;
  .imgUlOne {
    width: 5.11rem;
    height: 6rem;
    display: flex;
    .liImg {
      width: 100%;
      height: 6rem;
      background-image: url("../../assets/index/img1.png");
      background-size: 100% 100%;
    }
  }
  .imgUlTwo {
    width: 6.9rem;
    height: 6rem;
    display: flex;
    flex-direction: column;
    .twoTop {
      display: flex;
      width: 100%;
      height: 3rem;
      .liImg {
        width: 3.74rem;
        height: 100%;
        background-image: url("../../assets/index/img2.png");
        background-size: 100% 100%;
        &:last-of-type {
          width: 3, 16rem;
          height: 100%;
          background-image: url("../../assets/index/img3.png");
          background-size: 100% 100%;
        }
      }
    }
    .twoTwo {
      .liImg {
        width: 100%;
        height: 3rem;
        background-image: url("../../assets/index/img4.png");
        background-size: 100% 100%;
      }
    }
  }

  .liImg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      opacity: 0;
      transition: all 0.3s;
    }
    .name {
      color: #fff;
      font-size: 0.27rem;
      z-index: 2;
      opacity: 0;
    }
    .demoMore {
      margin-top: 0.1rem;
      padding-bottom: 0.04rem;
      font-size: 0.18rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: #fff;
      z-index: 2;
      opacity: 0;
      span {
        display: inline-block;
        width: 0.15rem;
        height: 0.21rem;
        background-image: url("../../assets/index/arrow.png");
        background-size: 100% 100%;
        margin-left: 0.1rem;
      }
      &::before {
        position: absolute;
        content: "";
        left: 0px;
        bottom: -0.04rem;
        width: 0%;
        height: 0.02rem;
        transition: all 0.3s;
      }
    }
    &:hover .mask,
    &:hover .name {
      opacity: 1;
    }
    &:hover .demoMore {
      opacity: 1;
      &::before {
        width: 100%;
        background-color: #fff;
      }
    }
  }
}
// 定制/案例
.demoBox {
  width: 12rem;
  margin: 1.2rem auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  .demo {
    width: 100%;
    height: 4rem;
    background-image: url("../../assets/index/demo1.png");
    background-size: 100% 100%;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    .demoTit {
      margin-top: 1.25rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
    }
    .demoTxt {
      margin-top: 0.19rem;
      font-size: 0.18rem;
      line-height: 0.18rem;
    }
    .demoMore {
      margin: 0 auto;
      margin-top: 0.6rem;
      padding-bottom: 0.04rem;
      font-size: 0.18rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        display: inline-block;
        margin-left: 0.19rem;
        width: 0.15rem;
        height: 0.21rem;
        background-image: url("../../assets/index/arrow.png");
        background-size: 100% 100%;
      }
      &::before {
        position: absolute;
        content: "";
        left: 0px;
        bottom: -0.04rem;
        width: 0%;
        height: 0.02rem;
        transition: all 0.3s;
      }
    }
    &:last-of-type {
      margin-top: 0.4rem;
      background-image: url("../../assets/index/demo2.png");
    }
    &:hover .demoMore {
      &::before {
        width: 100%;
        background-color: #fff;
      }
    }
  }
}
</style>