<template>
  <div id="app" class="pc" v-if="PCshow">
    <!-- :style="{display:headShow?'':'none'}" -->
    <router-link to="/pc/home" class="logo">
      <img src="./assets/logo.png" alt class="logo_img" />
    </router-link>
    <div id="nav">
      <div class="navUl">
        <div class="navLi">
          <router-link to="/pc/home" class="nav_home aa">首页</router-link>
        </div>
        <!-- <div class="navLi">
          <router-link to="/pc/whole" class="aa">全宅智能</router-link>
        </div> -->
        <div class="navLi" @mouseenter="enterThree" @mouseleave="leaveThree">
          <router-link to="/pc/product" class="product aa">产品中心</router-link>
          <!-- 产品中心 -->
          <div class="menu menuMain">
            <div class="menuBox" ref="box" style="transition:all 0.5s;">
              <div class="menuContainer">
                <ul class="ul">
                  <li class="li" ref="menuOneOne">
                    <a class="menu_a" @mouseenter="liEnter($event, 'menuOneOne')">超级主机</a>
                  </li>
                  <li class="li" ref="menuOneTwo">
                    <a class="menu_a" @mouseenter="liEnter($event, 'menuOneTwo')">智能面板</a>
                  </li>
                  <li class="li" ref="menuOneThree">
                    <a href="#" class="menu_a" @mouseenter="liEnter($event, 'menuOneThree')">窗帘电机</a>
                  </li>
                  <li class="li" ref="menuOneFour">
                    <a href="#" class="menu_a" @mouseenter="liEnter($event, 'menuOneFour')">单点</a>
                  </li>
                  <li class="li" ref="menuOneFive">
                    <a href="#" class="menu_a" @mouseenter="liEnter($event, 'menuOneFive')">其他控制</a>
                  </li>
                  <div class="line" :style="{ width: width, left: left + 'px' }"></div>
                </ul>
              </div>
              <div class="mainContainer" v-show="liFlagOne"
                :class="liFlagOne ? 'animated animatedFadeInUp fadeInUp' : ''">
                <div class="mainBox">
                  <ul class="main" style="margin-left:3.2rem">
                    <li class="main_li">
                      <router-link to="/pc/ProGateway" class="main_block">
                        <img src="./assets/index/headGateway.png" style="height:1rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProGateway" class="main_name">超级主机</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mainContainer" v-show="liFlagTwo"
                :class="liFlagTwo ? 'animated animatedFadeInUp fadeInUp' : ''">
                <div class="mainBox">
                  <!-- :style="{'margin-left':(marginLeftOne / 100 + 'rem')}" -->
                  <ul class="main" style="justify-content: center;">
                    <li class="main_li">
                      <router-link to="/pc/ProSwitch" class="main_block">
                        <img src="./assets/index/headSwitch.png" style="width:1.07rem;height:1.07rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSwitch" class="main_name">智能开关</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProScene" class="main_block">
                        <img src="./assets/index/headSecen.png" style="width:1.07rem;height:1.07rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProScene" class="main_name">双开场景面板</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProCard" class="main_block">
                        <img src="./assets/index/headCard.png" style="width:1.08rem;height:1.08rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProCard" class="main_name">插卡取电</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProUsb" class="main_block">
                        <img src="./assets/index/headUsb.png" style="width:1.07rem;height:1.07rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProUsb" class="main_name">五孔带USB电源面板</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProAir" class="main_block">
                        <img src="./assets/index/headAir.png" style="width:1.06rem;height:1.06rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProAir" class="main_name">空调面板</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mainContainer" v-show="liFlagThree"
                :class="liFlagThree ? 'animated animatedFadeInUp fadeInUp' : ''">
                <div class="mainBox">
                  <ul class="main" style="margin-left:3.2rem">
                    <li class="main_li">
                      <router-link to="/pc/ProCurtain" class="main_block">
                        <img src="./assets/index/headCurtain.png" style="width:1.28rem;height:0.77rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProCurtain" class="main_name">窗帘电机</router-link>
                    </li>

                  </ul>
                </div>
              </div>
              <div class="mainContainer" v-show="liFlagFour"
                :class="liFlagFour ? 'animated animatedFadeInUp fadeInUp' : ''">
                <div class="mainBox">
                  <ul class="main" style="justify-content: center;">
                    <li class="main_li">
                      <router-link to="/pc/ProSingleLight" class="main_block">
                        <img src="./assets/index/singleLight.png" style="width:1.42rem;height:1.08rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleLight" class="main_name">无线智能LED控制器</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleSwitch" class="main_block">
                        <img src="./assets/index/singleSwitch.png" style="width:1.42rem;height:1.05rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleSwitch" class="main_name">无线单点开关控制器</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleRemote" class="main_block">
                        <img src="./assets/index/singleRemote.png" style="width:1.42rem;height:1.05rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleRemote" class="main_name">无线单点遥控器</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSinglePower" class="main_block">
                        <img src="./assets/index/singlePower.png" style="width:1.42rem;height:1.05rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSinglePower" class="main_name">无线电源状态检测器</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mainContainer" v-show="liFlagFive"
                :class="liFlagFive ? 'animated animatedFadeInUp fadeInUp' : ''">
                <div class="mainBox">
                  <ul class="main" style="justify-content: center;"
                    :style="{ 'margin-left': (marginLeftOne / 100 + 'rem') }">
                    <li class="main_li">
                      <router-link to="/pc/ProSingleLed" class="main_block">
                        <img src="./assets/index/singleLed.png" style="width:1.23rem;height:0.98rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleLed" class="main_name">集中式LED调色控制器</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleRGBW" class="main_block">
                        <img src="./assets/index/singleRGB.png" style="width:1.35rem;height:0.85rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleRGBW" class="main_name">RGBW灯带</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleInfrared" class="main_block">
                        <img src="./assets/index/singleInf.png" style="width:1.08rem;height:1.04rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleInfrared" class="main_name">红外人体感应探头</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleHifi" class="main_block">
                        <img src="./assets/index/singleHi.png" style="width:1.35rem;height:0.79rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleHifi" class="main_name">Hi-Fi蓝牙功放</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleCheckin" class="main_block">
                        <img src="./assets/index/singleCheckin.png" style="width:0.47rem;height:1.44rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleCheckin" class="main_name">自助入住机</router-link>
                    </li>
                    <li class="main_li">
                      <router-link to="/pc/ProSingleRobot" class="main_block">
                        <img src="./assets/index/singleRobot.png" style="width:0.74rem;height:1.27rem" alt
                          class="main_img" />
                      </router-link>
                      <router-link to="/pc/ProSingleRobot" class="main_name">智能送物机器人</router-link>
                    </li>
                    <div class="left" @click="Left" v-show="leftFlagOne">
                      <van-icon name="arrow-left" />
                    </div>
                    <div class="right" @click="Right" v-show="leftFlagTwo">
                      <van-icon name="arrow" />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div class="shadow" v-show="showThree" @mouseenter="leaveThree"></div>
          </div>
        </div>
        <div class="navLi">
          <router-link to="/pc/about" class="aa">关于我们</router-link>
        </div>
      </div>
    </div>

    <router-view />
    <footer>
      <div class="footer">
        <div class="content">
          <div class="foot1">
            <div class="footlist">
              <ul>
                <router-link to="/pc/home" class="first_li">首页</router-link>
                <li>
                  <router-link to="/pc/custom">智能定制</router-link>
                </li>
                <li>
                  <router-link to="/pc/demo">精选案例</router-link>
                </li>
              </ul>
            </div>

            <!-- <div class="footlist">
              <ul>
                <router-link to="/pc/whole" class="first_li">全宅智能</router-link>
              </ul>
            </div> -->

            <div class="footlist">
              <ul>
                <router-link to="/pc/product" class="first_li">推荐产品</router-link>
                <li>
                  <router-link to="/pc/ProGateway">超级主机</router-link>
                </li>
                <li>
                  <router-link to="/pc/ProSwitch">智能开关</router-link>
                </li>
                <li>
                  <router-link to="/pc/ProScene">双开场景面板</router-link>
                </li>
                <li>
                  <router-link to="/pc/ProAir">空调面板</router-link>
                </li>
                <li>
                  <router-link to="/pc/ProCurtain">窗帘电机</router-link>
                </li>
              </ul>
            </div>

            <div class="footlist">
              <ul>
                <router-link to="/pc/about" class="first_li">关于我们</router-link>
              </ul>
            </div>
          </div>
          <div class="foot2">
            <div>
              <p class="contus">联系我们</p>
              <p class="phone">
                <span>400-000-7027/18078948818 (9:00-19:00)</span>
              </p>
            </div>
          </div>
          <div class="copyright">
            <div class="cName">Copyright © 2020 隆胜（深圳）科技有限公司 All rights reserved</div>
            <div class="cName">
              <a style="color:white" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008920"
                target="_blank">粤公网安备 44030502008920号</a><br>
              <a style="color:white" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022033504号-2</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div id="top" @click="returnTop" v-if="topFlag">
      <van-icon name="back-top" />
    </div>
  </div>

  <div id="app" class="phone" v-else>
    <div class="header" :style="{ 'border-bottom': menuFlag ? '1px solid #999' : '1px solid #000' }">
      <img src="./assets/mobile/logo.png" class="img" @click="change('/mobile/home')" />
      <div class="menu" @click="menu">
        <div class="line" :class="menuFlag ? 'line1' : ''"></div>
        <div class="line" :style="{ opacity: menuFlag ? '0' : '1' }"></div>
        <div class="line" :class="menuFlag ? 'line2' : ''"></div>
      </div>
    </div>
    <!-- 高度超出解决浏览器底部占位bug -->
    <div class="menuList" @touchmove.prevent @scroll.prevent ref="menu"
      :style="{ height: menuFlag ? 'calc(150%)' : '0' }">
      <van-collapse v-model="activeNameOne" accordion v-show="menuFlag" class="vanCol">
        <van-collapse-item title="首页" name="1" class="item">
          <template #right-icon>
            <van-icon :name="activeNameOne == 1 ? 'cross' : 'plus'" />
          </template>
          <div class="box">
            <van-collapse v-model="activeNameOne" accordion>
              <van-collapse-item title="首页" name="1" @click.native="change('/mobile/home')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="智能定制" name="2" @click.native="change('/mobile/custom')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="精选案例" name="3" @click.native="change('/mobile/demo')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-collapse-item>
        <!-- <van-collapse-item title="全宅智能" name="2" @click.native="change('/mobile/whole')">
          <template #right-icon>
            <van-icon name="arrow" />
          </template>
        </van-collapse-item> -->
        <van-collapse-item title="产品中心" name="3" class="item">
          <template #right-icon>
            <van-icon :name="activeNameOne == 3 ? 'cross' : 'plus'" />
          </template>
          <div class="box">
            <van-collapse v-model="activeNameOnes" accordion>
              <van-collapse-item title="超级主机" name="1" @click.native="change('/mobile/ProGateway')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="智能面板" name="2" @click.native="change('/mobile/ProPanel')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="窗帘电机" name="3" @click.native="change('/mobile/ProCurtains')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="单点" name="4" @click.native="change('/mobile/ProSinglePoint')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="其他控制" name="5" @click.native="change('/mobile/ProOther')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-collapse-item>
        <van-collapse-item title="关于我们" name="4" @click.native="change('/mobile/about')">
          <template #right-icon>
            <van-icon name="arrow" />
          </template>
        </van-collapse-item>
      </van-collapse>
    </div>
    <router-view />
    <div class="phone_footer">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item title="首页" name="1" class="item">
          <template #right-icon>
            <van-icon :name="activeNames == '1' ? 'cross' : 'plus'" />
          </template>
          <div class="box">
            <van-collapse v-model="activeNameOness" accordion>
              <van-collapse-item title="首页" name="1" disabled @click.native="change('/mobile/home')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="智能定制" name="2" disabled @click.native="change('/mobile/custom')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="精选案例" name="3" disabled @click.native="change('/mobile/demo')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-collapse-item>
        <!-- <van-collapse-item title="全宅智能" name="2" disabled @click.native="change('/mobile/whole')">
          <template #right-icon>
            <van-icon name />
          </template>
        </van-collapse-item> -->
        <van-collapse-item title="产品中心" name="3" class="item">
          <template #right-icon>
            <van-icon :name="activeNames == '3' ? 'cross' : 'plus'" />
          </template>
          <div class="box">
            <van-collapse v-model="activeNameOness" accordion>
              <van-collapse-item title="超级主机" name="1" disabled @click.native="change('/mobile/ProGateway')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="智能面板" name="2" disabled @click.native="change('/mobile/ProPanel')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="窗帘电机" name="3" disabled @click.native="change('/mobile/ProCurtains')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="单点" name="4" disabled @click.native="change('/mobile/ProSinglePoint')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
              <van-collapse-item title="其他控制" name="5" disabled @click.native="change('/mobile/ProOther')">
                <template #right-icon>
                  <van-icon name />
                </template>
              </van-collapse-item>
            </van-collapse>
          </div>
        </van-collapse-item>
        <van-collapse-item title="关于我们" name="4" disabled @click.native="change('/mobile/about')">
          <template #right-icon>
            <van-icon name />
          </template>
        </van-collapse-item>
      </van-collapse>
      <div class="phone_fotTime">400-000-7027 / 18078948818</div>
      <div class="phone_fotTimes">服务时间 (9:00-19:00)</div>
      <div class="phone_fotCompany">Copyright &copy; 隆胜（深圳）科技有限公司 All rights reserved</div>
      <!-- 手机端样式调整 2023-01-30-->
      <div style="color:white" class="phone_fotCompany">
        <a style="color:white" class="phone_fotCompany" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008920" target="_blank">粤公网安备 44030502008920号</a>
        <a style="color:white" class="phone_fotCompany" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022033504号-2</a>
      </div>
    </div>
    <div id="tops" @click="returnTop" v-show="showPhone">
      <van-icon name="back-top" />
    </div>
  </div>
</template>

<script>
import TpScroll from "@/assets/js/scroll.js";
export default {
  data() {
    return {
      PCshow: "", //判断当前设备
      // pc端数据
      scrollTop: 0, // 记录当前的滚动距离
      screenWidth: 0, // 当前屏幕大小
      n: 0, //当前字体比例
      topFlag: false, //是否显示回到顶部icon

      marginLeftOne: 0, // 产品中心轮播图距离左边位置
      leftFlagOne: false, //是否显示轮播图左箭头
      leftFlagTwo: true, //是否显示轮播图右箭头

      showThree: false, //下拉遮罩
      oneFlag: false, //默认显示产品中心第一个的动画
      liFlagOne: true, // 产品中心移入第一个
      liFlagTwo: false, // 产品中心移入第二个
      liFlagThree: false, // 产品中心移入第三个
      liFlagFour: false, // 产品中心移入第三个
      liFlagFive: false, // 产品中心移入第三个
      width: "0.72rem", //当前line宽度
      left: 0, //line距离左边的位置
      oldLeft: "", // 上一次line位置
      curLeft: 0, // 当前line位置
      i: "menuOneOne", //初始化当前位置
      headShow: true,
      // 移动端数据
      showPhone: false,
      activeNames: '1',
      menuFlag: false,
      activeNameOne: 0,
      activeNameOnes: 0,
      activeNameOness: 0
    };
  },
  created() {
    var pathstr = this.$router.history._startLocation;
    // console.log(this.$router.history._startLocation);
    // console.log(window.location.href);
    // console.log(this.$route.path);
    if (this._isMobile()) {
      // console.log("手机");
      this.PCshow = false;
      // console.log(pathstr);
      if (pathstr == "/" || pathstr == "/pc/product") {
        // console.log("进入");
        pathstr = "/mobile/home";
        this.$router.push("/mobile/home");
      }
      // console.log(pathstr);
      // console.log("测试");
      let regex = /\/pc\/(\S*)/;
      // console.log(regex.test(pathstr));
      //测试是否匹配，进入则说明路由切换到电脑端
      if (regex.test(pathstr)) {
        let temp = pathstr.match(regex);
        // console.log("我执行了");
        if (temp != null) {
          pathstr = "/mobile/" + temp[1];
        }
        // 切换到手机端路由
        this.$router.push(pathstr);
      }
    } else {
      // console.log("电脑");
      // 电脑版本
      this.PCshow = true;
      //是pc端，需要将页面切换到pc端路由
      let regex = /\/mobile\/(\S*)/;
      //测试是否匹配，进入则说明路由切换到手机端
      // console.log(pathstr);
      if (pathstr == "/") {
        // console.log("进入");
        pathstr = "/pc/home";
        this.$router.push("/pc/home");
      }
      if (
        pathstr == "/mobile/ProPanel" ||
        pathstr == "/mobile/ProCurtains" ||
        pathstr == "/mobile/ProSinglePoint" ||
        pathstr == "/mobile/ProOther"
      ) {
        // console.log("进入");
        pathstr = "/pc/product";
        this.$router.push("/pc/product");
      }
      if (regex.test(pathstr)) {
        // console.log("我执行了");
        let temp = pathstr.match(regex);
        if (temp != null) {
          pathstr = "/pc/" + temp[1];
        }
        // 切换到pc端路由
        this.$router.push(pathstr);
      }
      const that = this;
      this.screenWidth = document.body.clientWidth;
      this.n = document.body.clientWidth / 19.2;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth;
          that.screenWidth = window.screenWidth;
          that.n = window.screenWidth / 19.2;
        })();
      };
    }
  },
  mounted() {
    // pc端初始化underline位置
    this.handleScroll();
    if (this.PCshow) {
      this.oldLeft = this.$refs.menuOneOne.getBoundingClientRect().left;
      this.$refs.box.style.height = "0rem";
    }
  },
  watch: {
    // 监测滚动距离显示回到顶部Icon
    scrollTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 90) {
        this.showPhone = true;
        if (scrollTop > 132) {
          this.topFlag = true;
        } else {
          this.topFlag = false;
          // this.headShow = true;
        }
      } else {
        this.topFlag = false;
        this.showPhone = false;
        // this.headShow = true;
      }
      // console.log(scrollTop)
      // if (scrollTop > 140) {
      //   this.headShow = false;
      // } else {
      //   // this.headShow = true;
      // }
    }
  },
  methods: {
    // 判断进入设备
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    // pc端方法
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },
    returnTop() {
      // 回到顶部
      // scrollTo(0, 0);
      var timer = setInterval(function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var ispeed = Math.floor(-scrollTop / 6);
        // console.log(ispeed);
        if (scrollTop == 0) {
          clearInterval(timer);
        }
        document.documentElement.scrollTop = document.body.scrollTop =
          scrollTop + ispeed;
      }, 30);
    },
    enterThree() {
      // console.log("进入");
      this.marginLeftOne = 0;
      this.showThree = true;
      this.$refs.box.style.opacity = 1;
      this.$refs.box.style.height = "3.4rem";
      this.oneFlag = true;
      this.left = 0;
      this.width = "0.72rem";
      this.i = "menuOneOne";
      this.leftFlagTwo = true;
      this.leftFlagOne = false;
    },
    leaveThree() {
      // console.log("离开");
      this.showThree = false;
      this.oneFlag = false;
      this.liFlagOne = true;
      this.$refs.box.style.height = "0rem";
      this.$refs.box.style.opacity = 0.8;
    },
    // 产品中心
    liEnter(e, i) {
      this.oldLeft = this.$refs[this.i].getBoundingClientRect().left;
      this.curLeft = e.target.getBoundingClientRect().left;
      var left = this.curLeft - this.oldLeft;
      this.width = e.target.getBoundingClientRect().width + "px";
      // console.log(left);
      switch (i) {
        case "menuOneOne":
          this.left = this.left + left;
          this.liFlagOne = true;
          this.liFlagTwo = false;
          this.liFlagThree = false;
          this.liFlagFour = false;
          this.liFlagFive = false;
          break;
        case "menuOneTwo":
          this.left = this.left + left;
          this.liFlagOne = false;
          this.liFlagTwo = true;
          this.liFlagThree = false;
          this.liFlagFour = false;
          this.liFlagFive = false;
          break;
        case "menuOneThree":
          this.left = this.left + left;
          this.liFlagOne = false;
          this.liFlagTwo = false;
          this.liFlagThree = true;
          this.liFlagFour = false;
          this.liFlagFive = false;
          break;
        case "menuOneFour":
          this.left = this.left + left;
          this.liFlagOne = false;
          this.liFlagTwo = false;
          this.liFlagThree = false;
          this.liFlagFour = true;
          this.liFlagFive = false;
          break;
        case "menuOneFive":
          this.left = this.left + left;
          this.liFlagOne = false;
          this.liFlagTwo = false;
          this.liFlagThree = false;
          this.liFlagFour = false;
          this.liFlagFive = true;
          break;
      }
      this.i = i;
    },
    // 超级主机左键
    Left() {
      if (this.marginLeftOne == "-130") {
        this.marginLeftOne += 130;
        this.leftFlagOne = false;
        this.leftFlagTwo = true;
      }
    },
    // 超级主机右键
    Right() {
      if (this.marginLeftOne == 0) {
        this.marginLeftOne -= 130;
        this.leftFlagOne = true;
        this.leftFlagTwo = false;
      }
    },

    // 移动端方法
    change(href) {
      // console.log("我点击了" + href);
      this.$router.push(href);
      this.activeNameOne = [0];
      this.activeNames = [0];
      this.activeNameOness = [0];
      this.menuFlag = false;
      this.$refs.menu.style.height = "0";
      scrollTo(0, 0);
      // 允许页面滚动
      TpScroll.AddScroll();
    },
    menu() {
      this.menuFlag = !this.menuFlag;
      if (this.menuFlag) {
        TpScroll.RemoveScroll();
      } else {
        this.$refs.menu.style.height = "0";
        TpScroll.AddScroll();
      }
    },
    See() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502008920')
    }
  }
};
</script>

<style scoped lang="less">
#app {
  width: 100%;
  overflow: hidden;
}

// pc端样式
.pc {
  font-family: "PingFang SC", "OppoSans-M", Helvetica, "Microsoft YaHei";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #a7a7a7;
  min-width: 1200px;
  box-sizing: border-box;

  #top {
    position: fixed;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #f5f5f5;
    opacity: 0.6;
    bottom: 50px;
    right: 10px;
    z-index: 999;

    &:hover {
      opacity: 1;
      background-color: #fff;
      box-shadow: 0px 0px 10px #f2f2f2;
    }

    .van-icon-back-top {
      color: #000;
      font-size: 26px;
    }
  }

  a,
  span {
    font-family: "PingFang SC", "OppoSans-B", Helvetica, "Microsoft YaHei";
  }

  .logo {
    display: block;
    background-color: #000;
    display: flex;
    justify-content: center;
    padding-top: 0.6rem;
    padding-bottom: 0.32rem;
  }

  .logo .logo_img {
    width: 4.76rem;
    height: 0.28rem;
  }

  #nav {
    width: 100%;
    background-color: #000;

    .navUl {
      width: 8rem;
      margin: 0 auto;
      display: flex;
      background-color: #000;
      justify-content: space-between;

      .navLi {
        height: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;

        // position: relative;
        // background-color:red;
        // margin: 0.2rem 0px;
        .aa {
          color: #fff;
          // color: #999;
          overflow: hidden;
          font-size: 0.18rem;
          line-height: 0.18rem;
          font-weight: 400;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  // .mask
  .menu {
    position: absolute;
    z-index: 9;
    top: 1.8rem;
    left: 0rem;
    width: 19.2rem;
    color: black;
    display: flex;
    flex-direction: column;

    // overflow: hidden;
    .menuBox {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      overflow: hidden;
      z-index: 999;

      .menuContainer {
        display: flex;
        justify-content: center;

        .ul {
          display: flex;
          position: relative;
          padding: 0px;
          margin: 0px;

          .li {
            margin-right: 0.6rem;
            height: 0.93rem;
            line-height: 0.93rem;
            font-size: 0.18rem;
            font-weight: 500;
            background-color: #fff;
            cursor: pointer;

            &:nth-last-child(2) {
              margin-right: 0px;
            }

            .menu_a {
              color: #111;

              &:hover {
                color: #111 !important;
              }
            }
          }

          .line {
            position: absolute;
            width: 0.72rem;
            height: 1px;
            top: 0.62rem;
            background-color: #111;
            transition: all 0.3s ease 0s;
          }
        }
      }
    }

    .shadow {
      position: absolute;
      top: 0rem;
      left: 0px;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100vh;
      z-index: 99;
    }
  }

  .menuMain {

    // overflow: hidden;
    .menuBox {
      display: flex;
      flex-direction: column;

      .mainContainer {
        width: 100%;
        background-color: #fff;
        display: flex;
        justify-content: center;

        .mainBox {
          display: flex;
          align-items: flex-start;
          width: 12rem;
          overflow: hidden;
          position: relative;
          margin-bottom: 0.2rem;

          .main {
            padding: 0px;
            display: flex;
            width: 20rem;
            transition: all 0.3s ease 0s;

            .main_li {
              display: flex;
              flex-direction: column;
              margin-right: 0.2rem;

              .main_block {
                width: 2.06rem;
                height: 1.8rem;
                background-color: #eeeeee;
                display: flex;
                justify-content: center;
                align-items: center;

                .main_img {
                  width: 1.45rem;
                  height: 0.52rem;
                  transition: all 0.6s;
                }
              }

              .main_name {
                margin: 0.2rem 0px;
                width: 100%;
                text-align: center;
                font-size: 0.13rem;
                color: #333;
              }

              &:hover .main_block,
              &:hover .main_name {
                cursor: pointer;
              }

              &:hover .main_img {
                transform: scale(1.2, 1.2);
                -moz-transform: scale(1.2, 1.2);
                -webkit-transform: scale(1.2, 1.2);
                -o-transform: scale(1.2, 1.2);
              }
            }

            .left {
              position: absolute;
              top: 0.8rem;
              left: 0.1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 900;

              &:hover {
                cursor: pointer;
              }

              .van-icon {
                font-size: 0.2rem;
                margin-top: 0.2rem;
                color: #ff9000;
              }
            }

            .right {
              position: absolute;
              top: 0.8rem;
              right: 0.1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 900;

              &:hover {
                cursor: pointer;
              }

              .van-icon-arrow {
                font-size: 0.2rem;
                margin-top: 0.2rem;
                color: #ff9000;
              }
            }
          }
        }
      }
    }
  }

  // 动画
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 0.4rem, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 0.4rem, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
  }

  .animatedFadeInUp {
    opacity: 0;
  }

  .fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }

  footer {
    width: 100%;
    background-color: #000;
    padding-top: 0.6rem;

    .footer {
      width: 12rem;
      margin: 0 auto;

      .content {
        box-sizing: border-box;

        .foot1 {
          margin-bottom: 0.71rem;
          color: #666;
          font-size: 0.14rem;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          margin-bottom: 1rem;

          .footlist {
            text-align: left;
            font-size: 0.16rem;

            .first_li {
              display: block;
              font-weight: bold;
              color: #fff;
              font-size: 0.18rem;
              margin-bottom: 0.4rem;
            }

            ul {
              padding: 0;
            }

            ul>li {
              margin-bottom: 0.2rem;
              line-height: 0.2rem;
              text-align: left;
            }

            ul>li>a {
              color: rgba(255, 255, 255, 0.6);
              font-weight: 500;
            }

            ul>li>a:hover {
              color: #fff;
            }
          }
        }

        .foot2 {
          text-align: left;
          font-weight: 500;
          padding-bottom: 0.25rem;
          border-bottom-color: #fff;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          box-sizing: border-box;
          position: relative;

          .contus {
            font-size: 0.18rem;
            font-weight: bold;
            line-height: 0.22rem;
            color: #fff;
            font-weight: 600;
            margin-bottom: 0.19rem;
          }

          .phone {
            margin: 0;
            font-size: 0.24rem;
            line-height: 0.33rem;
            color: #fff;
            font-weight: bold;

            .time {
              margin-left: 0.09rem;
              vertical-align: 3px;
              font-size: 0.14rem;
              line-height: 0.2rem;
            }
          }

          .contact {
            position: absolute;
            right: 0;
            bottom: 0.21rem;
            display: flex;
            align-items: center;

            .wechat {
              display: block;
              width: 0.24rem;
              height: 0.24rem;
              background-image: url("./assets/wechat.png");

              &:hover {
                background-image: url("./assets/wechat_active.png");
              }
            }

            .sina {
              display: block;
              width: 0.24rem;
              height: 0.24rem;
              background-image: url("./assets/sina.png");
              margin-left: 0.21rem;

              &:hover {
                background-image: url("./assets/sina_active.png");
              }
            }

            .service {
              line-height: 0.4rem;
              text-align: center;
              display: block;
              font-size: 0.14rem;
              width: 1.23rem;
              height: 0.4rem;
              margin-left: 0.21rem;
              background: 0 0;
              border: 1px solid rgba(255, 255, 255, 0.6);
              border-radius: 0.02rem;
              position: relative;

              .img {
                position: relative;
                z-index: 1;
                vertical-align: middle;
                display: inline-block;
                margin-right: 5px;
                width: 0.19rem;
                height: 0.16rem;
                background: url("./assets/online_service.png") center no-repeat;
                background-size: cover;
              }

              span {
                position: relative;
                z-index: 1;
                color: rgba(255, 255, 255, 0.6);
                transition: all 0.3s;
              }

              &:hover {
                span {
                  color: #000;
                }

                .img {
                  background: url("./assets/online_service_active.png") center no-repeat;
                }
              }

              &::before {
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 2px;
                background: #fff;
                transition: all 0.3s;
                width: 0;
              }

              &:hover::before {
                transition: all 0.3s;
                width: 100%;
              }
            }
          }
        }
      }

      .copyright {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 0.22rem;
        padding-bottom: 0.22rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #ffffff;

        .cName {
          margin-bottom: 0.1rem;
        }

        .ba {
          cursor: pointer;
        }
      }
    }
  }
}

// 移动端样式
.phone {
  width: 100%;
  background-color: #000;

  #tops {
    position: fixed;
    width: 0.88rem;
    height: 0.88rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    bottom: 0.6rem;
    right: 0.3rem;
    z-index: 999;
    box-shadow: 0px 0px 0.1rem #f2f2f2;

    .van-icon-back-top {
      color: #000;
      font-size: 26px;
      opacity: 0.6;
    }
  }

  /deep/ .van-cell,
  /deep/.van-collapse-item__content {
    background-color: #000;
  }

  .van-cell::after {
    border-bottom: 0px;
  }

  /deep/[class*="van-hairline"]::after {
    border: 0px;
  }

  /deep/ .van-collapse-item--border::after {
    border-top: 0 !important;
  }

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0px;
    border: 0px;
  }

  .header {
    width: calc(100% - 0.6rem);
    height: 0.96rem;
    margin: 0 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;

    .img {
      width: 3.9rem;
      height: 0.22rem;
    }

    .menu {
      .line {
        width: 22px;
        height: 2px;
        background-color: #fff;
        margin-top: 5px;
        transition: all 0.7s;

        &:first-of-type {
          margin-top: 0px;
        }
      }

      .line1 {
        margin-top: -1px;
        transform: rotateZ(-45deg);
      }

      .line2 {
        margin-top: -9px;
        transform: rotateZ(45deg);
      }
    }
  }

  .menuList {
    position: absolute;
    top: 0.96rem;
    left: 0px;
    width: 100%;
    padding: 0 0.3rem;
    background-color: #000;
    z-index: 999;
    transition: height 0.4s;

    /deep/.van-collapse-item__content {
      padding: 0px;
    }

    .item {
      /deep/.van-collapse-item__wrapper {
        padding: 0 0.3rem;

        .van-cell {
          height: 0.6rem;
          padding-bottom: 0.3rem;
        }

        .van-collapse-item {
          border-bottom: 0;
        }

        span {
          color: #999;
        }
      }
    }

    .vanCol {
      transition: height 2s;

      .box {
        /deep/.van-cell {
          border-bottom: 0px;
        }

        .ul {
          .li {
            padding: 0px 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.8rem;
          }
        }
      }
    }

    /deep/ .van-cell::after {
      border-bottom: 0px;
    }

    .van-collapse-item {
      width: 100%;
      border-bottom: 1px solid #999;
    }

    /deep/ .van-cell {
      width: 100%;
      height: 0.96rem;
      padding: 0px;
      font-size: 0.24rem;
      line-height: 0.6rem;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .van-icon-arrow,
    .van-icon-cross,
    .van-icon-plus {
      font-size: 0.24rem;
      color: #999;
    }

    .van-icon-plus::before {
      transform: rotate(0deg);
      transition: all 0.3s ease;
    }

    .van-icon-cross::before {
      transform: rotate(90deg);
      transition: all 0.3s ease;
    }

    /deep/.van-cell__title {
      display: flex;
      justify-content: flex-start;
      align-content: center;

      .img {
        width: 3.9rem;
        height: 0.22rem;
      }

      span {
        width: 100%;
        text-align: left;
      }
    }
  }

  // 底部
  .phone_footer {
    /deep/ .van-collapse-item--border::after {
      border-top: 0px;
      border-bottom: 0;
    }

    padding-top: 0.37rem;
    background-color: #000;
    color: #fff;

    /deep/.van-hairline--top-bottom::after,
    /deep/.van-hairline-unset--top-bottom::after {
      border-width: 0;
    }

    /deep/ .van-collapse {
      padding: 0 0.3rem;
    }

    /deep/ .van-cell {
      width: 100%;
      height: 0.96rem;
      font-size: 0.24rem;
      line-height: 0.65rem;
      font-weight: bold;
      color: #ffffff;
    }

    /deep/.van-cell::after {
      border-bottom: 0 !important;
    }

    .van-icon-plus,
    .van-icon-cross {
      font-size: 0.24rem;
      color: #fff;
    }

    /deep/.van-collapse-item {
      border-bottom: 1px solid #999;
    }

    .item {
      /deep/.van-collapse-item {
        border-bottom: 0px solid #999;

        .van-cell {
          height: 0.6rem;
          padding-bottom: 0.3rem;
        }

        span {
          color: #999;
        }
      }
    }

    .van-icon-plus::before {
      transform: rotate(0deg);
      transition: all 0.4s ease;
    }

    .van-icon-cross::before {
      transform: rotate(90deg);
      transition: all 0.4s ease;
    }

    .van-icon-cross {
      color: #fff;
    }

    // /deep/ .van-collapse-item--border::after {
    //   left: 0.3rem;
    //   right: 0.3rem;
    //   border-bottom: 0px solid #999 !important;
    // }
    /deep/.van-collapse-item__content {
      padding: 0;
    }

    /deep/ .van-cell__title {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: flex-start;

      span {
        width: 100%;
      }
    }

    /deep/ .van-cell {
      display: flex;
      align-items: center;
      padding: 0;
    }

    .vanCol {
      transition: height 2s;

      .box {
        .ul {
          .li {
            // padding: 0 0.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 0.65rem;
          }
        }
      }
    }

    .phone_fotTime {
      margin: 0 0.3rem;
      margin-top: 0.6rem;
      text-align: left;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: bold;
    }

    .phone_fotTimes {
      margin: 0 0.3rem;
      margin-top: 0.12rem;
      text-align: left;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 300;
      color: #999999;
      padding-bottom: 20px;
      border-bottom: 1px solid #999;
    }

    .phone_fotCompany {
      margin-top: 0.2rem;
      font-size: 0.24rem;
      font-weight: 400;
      transform: scale(0.8);
    }

    .phone_fotBa {
      padding: 0.2rem 0;
      font-size: 0.24rem;
      font-weight: 400;
      transform: scale(0.8);
    }
  }
}
</style>
