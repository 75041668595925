<template>
  <div class="whole">
    <div class="banner">
      <div class="bannerBox"></div>
      <div class="bannerLight" :style="{ opacity: lightNum }"></div>
      <!-- 台灯 -->
      <div
        class="bannerDemo bannerTable"
        :style="{ opacity: tableFlag||openFlag || workModeFlag ? 1 : 0 }"
      ></div>
      <!-- 柜灯 -->
      <div
        class="bannerDemo bannerCab"
        :style="{ opacity: cabFlag||openFlag || workModeFlag ? 1 : 0 }"
      ></div>
      <!-- 浴室 -->
      <div
        class="bannerDemo bannerBath"
        :style="{ opacity: bathFlag||openFlag || wakeupModeFlag ? 1 : 0 }"
      ></div>
      <!-- 床头灯 -->
      <div
        class="bannerDemo bannerBedside"
        :style="{ opacity: (bedsideFlag ||openFlag&&bedsideFlag) ||  (workModeFlag&&bedsideFlag) || (moveModeFlag&&bedsideFlag)? 1 : 0 }"
      ></div>
      <!-- 背景灯 -->
      <div
        class="bannerDemo bannerBack"
        :style="{ opacity: (backFlag || openFlag&&backFlag) ||  (wakeupModeFlag&&backFlag) || (workModeFlag&&backFlag) || (moveModeFlag&&backFlag)? 1 : 0 }"
      ></div>
      <!-- 氛围灯 -->
      <div
        class="bannerDemo bannerAmbient"
        :style="{ opacity: ambientFlag ||openFlag  ||wakeupModeFlag? 1 : 0 }"
      ></div>
      <!-- 雾化玻璃 -->
      <div class="bannerDemo bannerGlass" :style="{ opacity: glassFlag ? 1 : 0 }"></div>
      <!-- 电视 -->
      <div
        class="bannerDemo bannerTv"
        :style="{ opacity: tvFlag || (moveModeFlag&&tvFlag)? 1 : 0 }"
      ></div>
      <!-- 空调 -->
      <div class="bannerDemo bannerAir" :style="{ opacity: airFlag ? 1 : 0 }"></div>
      <div class="selectList">
        <div class="sList">
          <audio ref="audio" loop :muted="true" :src="audioUrl">111</audio>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('wakeup')"
              :style="{ 'border':wakeupModeFlag?'0':'','background-image': wakeupModeFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                 wakeupModeFlag
                    ? require('../../assets/whole/mode1_open.png')
                    : require('../../assets/whole/mode1.png')
                "
                class="img"
              />
            </div>
            <div class="sName">晨起场景</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('workMode')"
              :style="{ 'border':workModeFlag?'0':'','background-image': workModeFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  workModeFlag
                    ? require('../../assets/whole/mode2_open.png')
                    : require('../../assets/whole/mode2.png')
                "
                class="img"
              />
            </div>
            <div class="sName">工作场景</div>
          </div>

          <div class="sLi">
            <div
              class="sImg"
              @click="change('moveMode')"
              :style="{ 'border':moveModeFlag?'0':'','background-image': moveModeFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  moveModeFlag
                    ? require('../../assets/whole/mode3_open.png')
                    : require('../../assets/whole/mode3.png')
                "
                class="img"
              />
            </div>
            <div class="sName">观影场景</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('close')"
              :style="{ 'border':closeFlag?'0':'','background-image': closeFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  closeFlag
                    ? require('../../assets/whole/mode4_open.png')
                    : require('../../assets/whole/mode4.png')
                "
                class="img"
              />
            </div>
            <div class="sName">全关场景</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('open')"
              :style="{ 'border':openFlag?'0':'','background-image': openFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  openFlag
                    ? require('../../assets/whole/mode5_open.png')
                    : require('../../assets/whole/mode5.png')
                "
                class="img"
              />
            </div>
            <div class="sName">全开场景</div>
          </div>
        </div>
        <div class="sList">
          <div class="sLi">
            <div
              class="sImg"
              @click="change('glass')"
              :style="{ 'border':glassFlag?'0':'','background-image': glassFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  glassFlag
                    ? require('../../assets/whole/wholeImg1_open.png')
                    : require('../../assets/whole/wholeImg1.png')
                "
                class="img"
              />
            </div>
            <div class="sName">雾化玻璃</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('tv')"
              :style="{ 'border':tvFlag?'0':'','background-image': tvFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  tvFlag
                    ? require('../../assets/whole/wholeImg2_open.png')
                    : require('../../assets/whole/wholeImg2.png')
                "
                class="img"
              />
            </div>
            <div class="sName">电视</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('air')"
              :style="{ 'border':airFlag?'0':'','background-image': airFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  airFlag
                    ? require('../../assets/whole/wholeImg3_open.png')
                    : require('../../assets/whole/wholeImg3.png')
                "
                class="img"
              />
            </div>
            <div class="sName">空调</div>
          </div>

          <div class="sLi">
            <div
              class="sImg"
              @click="change('table')"
              :style="{ 'border':tableFlag?'0':'','background-image': tableFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                 tableFlag
                    ? require('../../assets/whole/wholeImg4_open.png')
                    : require('../../assets/whole/wholeImg4.png')
                "
                class="img"
              />
            </div>
            <div class="sName">台灯</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('cab')"
              :style="{ 'border':cabFlag?'0':'','background-image': cabFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  cabFlag
                    ? require('../../assets/whole/wholeImg5_open.png')
                    : require('../../assets/whole/wholeImg5.png')
                "
                class="img"
              />
            </div>
            <div class="sName">柜灯</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('bedside')"
              :style="{ 'border':bedsideFlag?'0':'','background-image': bedsideFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  bedsideFlag
                    ? require('../../assets/whole/wholeImg6_open.png')
                    : require('../../assets/whole/wholeImg6.png')
                "
                class="img"
              />
            </div>
            <div class="sName">床头灯</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('back')"
              :style="{ 'border':backFlag?'0':'','background-image': backFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  backFlag
                    ? require('../../assets/whole/wholeImg7_open.png')
                    : require('../../assets/whole/wholeImg7.png')
                "
                class="img"
              />
            </div>
            <div class="sName">背景灯</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('ambient')"
              :style="{ 'border':ambientFlag?'0':'','background-image': ambientFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  ambientFlag
                    ? require('../../assets/whole/wholeImg8_open.png')
                    : require('../../assets/whole/wholeImg8.png')
                "
                class="img"
              />
            </div>
            <div class="sName">氛围灯</div>
          </div>
          <div class="sLi">
            <div
              class="sImg"
              @click="change('bath')"
              :style="{ 'border':bathFlag?'0':'','background-image': bathFlag ? `url(${img})` : '' }"
            >
              <img
                :src="
                  bathFlag
                    ? require('../../assets/whole/wholeImg9_open.png')
                    : require('../../assets/whole/wholeImg9.png')
                "
                class="img"
              />
            </div>
            <div class="sName">卫浴灯</div>
          </div>

          <div class="sLi">
            <div
              class="sImg"
              @click="change('bgm')"
              :style="{ 'border':bgmFlag?'0':'','background-image': bgmFlag ? `url(${img})` : '',transform:musicRotate }"
            >
              <img
                :src="
                  bgmFlag
                    ? require('../../assets/whole/wholeImg10_open.png')
                    : require('../../assets/whole/wholeImg10.png')
                "
                class="img"
              />
            </div>
            <div class="sName">背景音乐</div>
          </div>
        </div>
      </div>
   
    </div>
    <!-- 亮度调节 -->
    <div class="sec">
      <div class="secTit">亮度调节，一点到位。为你点亮美好生活</div>
      <div
        class="secBox"
        @dragstart="dragenter($event)"
        @dragenter="dragenter($event)"
        @dragover="dragenter($event)"
      >
        <div
          class="img1"
          @dragstart="dragenter($event)"
          @dragenter="dragenter($event)"
          @dragover="dragenter($event)"
          :style="{ filter: filter }"
        ></div>
        <div
          class="img2"
          @dragenter="dragenter($event)"
          @dragover="dragenter($event)"
          :style="{ width: width, background: background }"
        ></div>
        <!-- <div
          class="img3"
          :style="{left:width}"
        ></div> -->
        <div class="line" @mousedown="move($event)" :style="{ left: positionX }" ref="lis">
          <div class="box">
            <img src="../../assets/whole/icon.png" class="img" />
          </div>
        </div>
      </div>
      <div class="bar">
        <div class="left">1%</div>
        <div class="right">100%</div>
      </div>
      <div class="text">0%-100%亮度调节。缓亮缓灭，状态记忆。可明可暗，掌控由你。细腻柔和，点亮全新生活。</div>
    </div>
  </div>
</template>
<script>
export default {
  name:'Whole',
  data() {
    return {
      audioUrl: require("../../assets/bgm.mp3"),
      img: require("../../assets/whole/selectBgc.png"),
      wakeupModeFlag: false,
      workModeFlag: false,
      bedsideFlag: false,
      openFlag: false,
      closeFlag: false,
      tableFlag: false,
      cabFlag: false,
      ambientFlag: false,
      bathFlag: false,
      bgmFlag: false,
      backFlag: false,
      moveModeFlag: false,
      glassFlag: false,
      tvFlag: false,
      airFlag: false,
      lightNum: 0.75,
      width: "6rem",
      positionX: "calc(50% - 0.03rem)",
      screenWidth: 0,
      background:
        "linear-gradient(to right, rgba(0, 0, 0, 0.306), rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0))",
      n: 0,
      filter: "brightness(100%)",
      musicRotate: "",
      musicNum: 0,
      interval: null
    };
  },
  mounted() {
    const that = this;
    //secBox
    var div = document.getElementsByClassName('secBox');
    console.log(div);
    var width = div[0].clientWidth;
    console.log(width);
    console.log(document.body.clientWidth);
    this.screenWidth = document.body.clientWidth;
    this.n = document.body.clientWidth / 19.2;
    console.log(this.n);
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        that.n = window.screenWidth / 19.2;
        // console.log(window.screenWidth);
      })();
    };
  },
  methods: {
    // 静音修改
    // changeMute() {
    //   this.$refs.audio.muted = !this.$refs.audio.muted;
    // },
    change(type) {
      // console.log(type);
      switch (type) {
        // 晨起场景
        case "wakeup":
          // console.log('我点击了')
          this.wakeupModeFlag = !this.wakeupModeFlag;
          if (this.wakeupModeFlag) {
            this.wokeModeClose();
            this.moveModeClose();
            this.wakeupOpen();
          } else {
            this.lightNum += 0.3;
            this.wakeupClose();
          }
          break;
        //  工作场景
        case "workMode":
          this.workModeFlag = !this.workModeFlag;
          if (this.workModeFlag) {
            if (this.wakeupModeFlag) {
              this.lightNum += 0.3;
            }
            this.moveModeClose();
            this.wakeupClose();
            this.wokeModeOpen();
          } else {
            this.wokeModeClose();
          }
          break;
        // 观影场景
        case "moveMode":
          this.moveModeFlag = !this.moveModeFlag;
          if (this.moveModeFlag) {
            if (this.wakeupModeFlag) {
              this.lightNum += 0.3;
            }
            this.wakeupClose();
            this.wokeModeClose();
            this.moveModeOpen();
          } else {
            this.moveModeClose();
          }
          break;
        // 全关场景
        case "close":
          this.close();
          break;
        // 全开场景
        case "open":
          if (this.wakeupModeFlag) {
            this.lightNum += 0.3;
          }
          this.open();
          break;
        // 台灯
        case "table":
          this.moveModeFlag = false;
          this.tableFlag = !this.tableFlag;
          if (this.tableFlag) {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            this.closeFlag = false;
            this.lightNum -= 0.1;
          } else {
            this.workModeFlag = false;
            this.openFlag = false;
            this.lightNum += 0.1;
          }
          break;
        // 柜灯
        case "cab":
          this.moveModeFlag = false;
          this.cabFlag = !this.cabFlag;
          if (this.cabFlag) {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            this.closeFlag = false;
            this.lightNum -= 0.1;
          } else {
            this.workModeFlag = false;
            this.openFlag = false;
            this.lightNum += 0.1;
          }
          break;
        // 床头灯
        case "bedside":
          this.bedsideFlag = !this.bedsideFlag;
          if (this.bedsideFlag) {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            this.closeFlag = false;
            this.lightNum -= 0.1;
          } else {
            this.workModeFlag = false;
            this.openFlag = false;
            this.moveModeFlag = false;
            this.lightNum += 0.1;
          }
          break;
        // 背景灯
        case "back":
          this.backFlag = !this.backFlag;
          if (this.backFlag) {
            this.closeFlag = false;
            this.lightNum -= 0.1;
          } else {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            this.workModeFlag = false;
            this.moveModeFlag = false;
            this.openFlag = false;
            this.lightNum += 0.1;
          }
          break;
        // 氛围灯
        case "ambient":
          this.workModeFlag = false;
          this.moveModeFlag = false;
          this.ambientFlag = !this.ambientFlag;
          if (this.ambientFlag) {
            this.closeFlag = false;
            this.lightNum -= 0.1;
          } else {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            this.openFlag = false;
            this.lightNum += 0.1;
          }
          break;
        // 卫浴灯
        case "bath":
          this.workModeFlag = false;
          this.moveModeFlag = false;
          this.bathFlag = !this.bathFlag;
          if (this.bathFlag) {
            this.closeFlag = false;
            // this.lightNum -= 0.1;
          } else {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            this.openFlag = false;
            // this.lightNum += 0.1;
          }
          break;
        // 背景音乐
        case "bgm":
          this.workModeFlag = false;
          this.moveModeFlag = false;
          this.bgmFlag = !this.bgmFlag;
          this.closeFlag = false;
          this.openFlag = false;
          if (this.bgmFlag) {
            // 打开背景音乐
            // this.$refs.audio.volume=0.2
            this.$refs.audio.play(); //点击触发 点击音效
            var that = this;
            this.interval = setInterval(() => {
              if (that.musicNum > 360) {
                that.musicNum -= 360;
              }
              that.musicNum = that.musicNum + 4;
              that.musicRotate = "rotate(" + that.musicNum + "deg)";
            }, 100);
          } else {
            if (this.wakeupModeFlag) {
              this.wakeupModeFlag = false;
              this.lightNum += 0.3;
            }
            // 图标归位
            this.musicRotate = "rotate(0deg)";
            this.$refs.audio.pause();
            this.$refs.audio.currentTime = 0;
            clearInterval(this.interval);
          }
          break;
        case "glass":
          if (this.wakeupModeFlag) {
            this.wakeupModeFlag = false;
            this.lightNum += 0.3;
          }
          this.workModeFlag = false;
          this.moveModeFlag = false;
          this.glassFlag = !this.glassFlag;
          this.closeFlag = false;
          this.openFlag = false;
          break;
        case "tv":
          if (this.wakeupModeFlag) {
            this.wakeupModeFlag = false;
            this.lightNum += 0.3;
          }
          this.workModeFlag = false;
          this.closeFlag = false;
          this.openFlag = false;
          this.tvFlag = !this.tvFlag;
          if (this.tvFlag) {
            this.lightNum -= 0.1;
          } else {
            this.moveModeFlag = false;
            this.lightNum += 0.1;
          }
          break;
        case "air":
          if (this.wakeupModeFlag) {
            this.wakeupModeFlag = false;
            this.lightNum += 0.3;
          }
          this.workModeFlag = false;
          this.moveModeFlag = false;
          this.closeFlag = false;
          this.openFlag = false;
          this.airFlag = !this.airFlag;
          if (this.airFlag) {
            this.lightNum -= 0.1;
          } else {
            this.lightNum += 0.1;
          }
          break;
      }
    },
    // 晨起模式开
    wakeupOpen() {
      this.wakeupModeFlag = true;
      this.closeFlag = false;
      this.openFlag = false;
      if (this.tableFlag) {
        this.tableFlag = false;
        this.lightNum += 0.1;
      }
      if (this.cabFlag) {
        this.cabFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bedsideFlag) {
        this.bedsideFlag = false;
        this.lightNum += 0.1;
      }
      if (!this.ambientFlag) {
        this.ambientFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.bathFlag) {
        this.bathFlag = true;
      }
      if (!this.backFlag) {
        this.backFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.bgmFlag) {
        this.bgmFlag = true;
        // this.$refs.audio.volume=0.02
        // 打开背景音乐
        this.$refs.audio.play(); //点击触发 点击音效
        var that = this;
        this.interval = setInterval(() => {
          if (that.musicNum > 360) {
            that.musicNum -= 360;
          }
          that.musicNum = that.musicNum + 4;
          that.musicRotate = "rotate(" + that.musicNum + "deg)";
        }, 100);
      }
    },
    // 晨起模式关
    wakeupClose() {
      this.wakeupModeFlag = false;
      if (this.ambientFlag) {
        this.ambientFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bathFlag) {
        this.bathFlag = false;
      }
      if (this.backFlag) {
        this.backFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bgmFlag) {
        this.bgmFlag = false;
        // 图标归位
        this.musicRotate = "rotate(0deg)";
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        clearInterval(this.interval);
      }
    },
    // 工作模式开
    wokeModeOpen() {
      this.workModeFlag = true;
      this.closeFlag = false;
      this.openFlag = false;
      if (this.ambientFlag) {
        this.ambientFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bathFlag) {
        this.bathFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bgmFlag) {
        this.bgmFlag = false;
        // 图标归位
        this.musicRotate = "rotate(0deg)";
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        clearInterval(this.interval);
      }
      if (!this.tableFlag) {
        this.tableFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.cabFlag) {
        this.cabFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.backFlag) {
        this.backFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.bedsideFlag) {
        this.bedsideFlag = true;
        this.lightNum -= 0.1;
      }
    },
    // 工作模式关
    wokeModeClose() {
      this.workModeFlag = false;
      if (this.tableFlag) {
        this.tableFlag = false;
        this.lightNum += 0.1;
      }
      if (this.cabFlag) {
        this.cabFlag = false;
        this.lightNum += 0.1;
      }
      if (this.backFlag) {
        this.backFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bedsideFlag) {
        this.bedsideFlag = false;
        this.lightNum += 0.1;
      }
    },
    // 观影模式开
    moveModeOpen() {
      this.moveModeFlag = true;
      this.closeFlag = false;
      this.openFlag = false;
      if (this.tableFlag) {
        this.tableFlag = false;
        this.lightNum += 0.1;
      }
      if (this.cabFlag) {
        this.cabFlag = false;
        this.lightNum += 0.1;
      }
      if (this.ambientFlag) {
        this.ambientFlag = false;
        this.lightNum += 0.1;
      }
      this.bathFlag = false;
      if (this.bgmFlag) {
        this.bgmFlag = false;
        // 图标归位
        this.musicRotate = "rotate(0deg)";
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        clearInterval(this.interval);
      }
      if (!this.tvFlag) {
        this.tvFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.backFlag) {
        this.backFlag = true;
        this.lightNum -= 0.1;
      }
      if (!this.bedsideFlag) {
        this.bedsideFlag = true;
        this.lightNum -= 0.1;
      }
    },
    // 观影模式关
    moveModeClose() {
      this.moveModeFlag = false;
      if (this.tvFlag) {
        this.tvFlag = false;
        this.lightNum += 0.1;
      }
      if (this.backFlag) {
        this.backFlag = false;
        this.lightNum += 0.1;
      }
      if (this.bedsideFlag) {
        this.bedsideFlag = false;
        this.lightNum += 0.1;
      }
    },
    // 全开模式
    open() {
      this.lightNum = 0.25;
      this.openFlag = true;
      this.closeFlag = false;
      this.wakeupModeFlag = false;
      this.workModeFlag = false;
      this.moveModeFlag = false;

      this.tableFlag = true;
      this.cabFlag = true;
      this.ambientFlag = true;
      this.bedsideFlag = true;
      this.backFlag = true;
      this.bathFlag = true;
      if (this.tvFlag) {
        this.lightNum -= 0.1;
      }
      if (this.airFlag) {
        this.lightNum -= 0.1;
      }
    },
    // 全关模式
    close() {
      this.closeFlag = true;
      this.openFlag = false;
      this.wakeupModeFlag = false;
      this.workModeFlag = false;
      this.moveModeFlag = false;
      this.tableFlag = false;
      this.cabFlag = false;
      if (this.bgmFlag) {
        this.bgmFlag = false;
        // 图标归位
        this.musicRotate = "rotate(0deg)";
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        clearInterval(this.interval);
      }
      this.tvFlag = false;
      this.airFlag = false;
      this.glassFlag = false;
      this.ambientFlag = false;
      this.bathFlag = false;
      this.bedsideFlag = false;
      this.backFlag = false;
      this.lightNum = 0.75;
    },
    move(e) {
      console.log('woew rjwe');
       console.log(e.clientX);
       console.log(this.$refs.lis.getBoundingClientRect())
      let X = e.clientX - this.$refs.lis.getBoundingClientRect().x;
      var left;
      document.onmousemove = e => {
        left = e.clientX - X;
        console.log(left)
        // console.log(this.screenWidth /100)
        if (left < this.screenWidth) {
          var n = this.n;
          let l = left / this.screenWidth;
          let li = (1 - l) * 20 + 100;
          this.filter = `brightness(${li}%)`;
          this.background = `linear-gradient(to right,rgba(0,0,0,${(l * l +
            0.2) *
            0.7}),rgba(0,0,0,${(l * l + 0.2) * 0.6}),rgba(0,0,0,${l *
            l *
            0.6}),rgba(0,0,0,0))`;

          if (left / n > -0.04) {
            this.positionX = left / n + "rem";
            if (l > 0.3) {
              this.width = left / n + 3 + "rem";
            } else {
              this.width = left / n + (l / 0.3) * 3 + "rem";
            }
            // console.log(this.width);
          }
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      return false;
    },
    dragenter(e) {
      e.preventDefault();
    }
  },
  watch: {
    wakeupModeFlag(v) {
      if (v) {
        this.lightNum -= 0.3;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.whole{
  width: 100%;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.banner {
  width: 100%;
  height: 9rem;
  // min-width: 1162px;
  position: relative;
  .bannerBox {
    width: 100%;
    height: 5.5rem;
    background-image: url("../../assets/whole/wholeBanner.png");
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // min-width: 1162px;
  }
  .bannerLight {
    position: absolute;
    width: 100%;
    height: 5.5rem;
    background-color: rgb(0, 0, 0);
    // opacity: 0.8;
    transition: opacity 1.5s;
    // min-width: 1162px;
  }

  .bannerTable {
    // position: absolute
    width: 1rem;
    height: 0.36rem;
    transform: translateX(1.4rem) translateY(2.66rem);
    //  transform: translateX(224px) translateY(386px);
    background: url("../../assets/whole/table.png");
  }
  .bannerCab {
    width: 2.2rem;
    height: 3rem;
    transform: translateX(0.6rem) translateY(3.33rem);
    // transform: translateX(224px) translateY(376px);
    background: url("../../assets/whole/cab.png");
  }
  .bannerBath {
    width: 2.25rem;
    height: 2.8rem;
    transform: translateX(2.48rem) translateY(1.19rem);
    // transform: translateX(224px) translateY(376px);
    background: url("../../assets/whole/bath.png");
  }
  .bannerBedside {
    width: 3.9rem;
    height: 2rem;
    transform: translateX(8.1rem) translateY(1.45rem);
    // transform: translateX(1284px) translateY(195px);
    background: url("../../assets/whole/bedside.png");
  }
  .bannerBack {
    width: 1.8rem;
    height: 2.66rem;
    transform: translateX(9.17rem) translateY(0.35rem);
    background: url("../../assets/whole/back.png");
  }
  .bannerAmbient {
    width: 10.18rem;
    height: 1.9rem;
    transform: translateX(0rem) translateY(0rem);
    // transform: translateX(1555px) translateY(54px);
    background: url("../../assets/whole/ambient.png");
  }
  .bannerGlass {
    width: 2.25rem;
    height: 2.8rem;
    transform: translateX(2.48rem) translateY(1.19rem);
    // transform: translateX(395px) translateY(170px);
    background: url("../../assets/whole/glass.png");
  }
  .bannerTv {
    width: 1.45rem;
    height: 4.59rem;
    transform: translateX(2.84rem) translateY(0.925rem);
    // transform: translateX(453px) translateY(133px);
    background: url("../../assets/whole/tv.png");
  }
  .bannerAir {
    width: 7.9rem;
    height: 0.5rem;
    transform: translateX(1.03rem) translateY(0.47rem);
    // transform: translateX(161px) translateY(65px);
    background: url("../../assets/whole/air.png");
  }
  // 灯公共样式，必须加在后面
  .bannerDemo {
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0;
    transition: opacity 1.5s;
    z-index: 2;
  }
  .selectList {
    position: absolute;
    left: calc((19.2rem - 19.2rem) / 2);
    top: 6rem;
    width: 12rem;
    height: 3.68rem;
    background: #ffffff;
    box-shadow: 0px 0.05rem 0.1rem 0px rgba(114, 114, 114, 0.27);
    border-radius: 0.2rem;
    padding: 0.45rem 0.3rem;
    box-sizing: border-box;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sList {
      display: flex;
      justify-content: space-between;
      &:first-of-type {
        padding: 0rem 1.65rem;
        .sLi {
          .sImg {
            width: 0.84rem;
            height: 0.84rem;
          }
        }
      }
      .sLi {
        display: flex;
        flex-direction: column;
        align-items: center;
        .sImg {
          width: 0.68rem;
          height: 0.68rem;
          border: 2px solid #f8f8f8;
          border-radius: 50%;
          box-sizing: border-box;
          display: flex;
          background-size: 100% 100%;
          justify-content: center;
          align-content: center;
          cursor: pointer;
          position: relative;
          // overflow: hidden;
          .bgc {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
          }
          .img {
            width: 100%;
            height: 100%;
            z-index: 9;
          }
        }
        .sName {
          width: 0.98rem;
          margin-top: 0.19rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
      }
    }
  }
}
// 亮度调节
.sec {
  width: 100%;
  height: 10rem;

  background-color: #fff;
  margin-top: 1.5rem;
  .secTit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0.79rem;
  }
  .secBox {
    position: relative;
    width: 100%;
    height: 7.2rem;
    overflow: hidden;
    margin-bottom: 0.31rem;
    .img1 {
      position: absolute;
      width: 100%;
      height: 7.2rem;
      background-image: url("../../assets/whole/img1.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .img2 {
      position: absolute;
      height: 7.2rem;
      // background-image: url("../../assets/whole/img2.png");
      // background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.472);
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.306),
        rgba(0, 0, 0, 0.26),
        rgba(0, 0, 0, 0.14),
        rgba(0, 0, 0, 0)
      );
      background-size: cover;
      z-index: 2;
    }
    .img3 {
      position: absolute;
      width: 2rem;
      height: 7.2rem;
      background-image: url("../../assets/whole/img3.png");
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
    }
    .line {
      position: absolute;
      width: 0.06rem;
      height: 7.2rem;
      background-color: #fff;
      top: 0;
      z-index: 9;
      cursor: ew-resize;
    }
    .img {
      cursor: ew-resize;
      position: absolute;
      width: 0.3rem;
      height: 0.3rem;
      background-color: #fff;
      border: 1px solid #333;
      border-radius: 50%;
      top: calc(50% - 0.15rem);
      left: calc(50% - 0.15rem);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
    }
  }
  .bar {
    width: 100%;
    height: .5rem;
    background: linear-gradient(90deg, #000000 16%, #ffffff);
    display: flex;
    padding: 0 1.04rem;
    justify-content: space-between;
    align-items: center;
    font-size: 0.25rem;
    line-height: 0.48rem;
    font-weight: 400;
    .left {
      color: #fff;
    }
    .right {
      color: #000;
    }
  }
  .text {
    padding-top: 0.39rem;
    width: 100%;
    text-align: center;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    color: #333333;
  }
}
</style>
