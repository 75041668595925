var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whole"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerBox"}),_c('div',{staticClass:"bannerLight",style:({ opacity: _vm.lightNum })}),_c('div',{staticClass:"bannerDemo bannerTable",style:({ opacity: _vm.tableFlag||_vm.openFlag || _vm.workModeFlag ? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerCab",style:({ opacity: _vm.cabFlag||_vm.openFlag || _vm.workModeFlag ? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerBath",style:({ opacity: _vm.bathFlag||_vm.openFlag || _vm.wakeupModeFlag ? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerBedside",style:({ opacity: (_vm.bedsideFlag ||_vm.openFlag&&_vm.bedsideFlag) ||  (_vm.workModeFlag&&_vm.bedsideFlag) || (_vm.moveModeFlag&&_vm.bedsideFlag)? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerBack",style:({ opacity: (_vm.backFlag || _vm.openFlag&&_vm.backFlag) ||  (_vm.wakeupModeFlag&&_vm.backFlag) || (_vm.workModeFlag&&_vm.backFlag) || (_vm.moveModeFlag&&_vm.backFlag)? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerAmbient",style:({ opacity: _vm.ambientFlag ||_vm.openFlag  ||_vm.wakeupModeFlag? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerGlass",style:({ opacity: _vm.glassFlag ? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerTv",style:({ opacity: _vm.tvFlag || (_vm.moveModeFlag&&_vm.tvFlag)? 1 : 0 })}),_c('div',{staticClass:"bannerDemo bannerAir",style:({ opacity: _vm.airFlag ? 1 : 0 })}),_c('div',{staticClass:"selectList"},[_c('div',{staticClass:"sList"},[_c('audio',{ref:"audio",attrs:{"loop":"","muted":true,"src":_vm.audioUrl}},[_vm._v("111")]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.wakeupModeFlag?'0':'','background-image': _vm.wakeupModeFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('wakeup')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.wakeupModeFlag
                  ? require('../../assets/whole/mode1_open.png')
                  : require('../../assets/whole/mode1.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("晨起场景")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.workModeFlag?'0':'','background-image': _vm.workModeFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('workMode')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.workModeFlag
                  ? require('../../assets/whole/mode2_open.png')
                  : require('../../assets/whole/mode2.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("工作场景")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.moveModeFlag?'0':'','background-image': _vm.moveModeFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('moveMode')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.moveModeFlag
                  ? require('../../assets/whole/mode3_open.png')
                  : require('../../assets/whole/mode3.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("观影场景")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.closeFlag?'0':'','background-image': _vm.closeFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('close')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.closeFlag
                  ? require('../../assets/whole/mode4_open.png')
                  : require('../../assets/whole/mode4.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("全关场景")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.openFlag?'0':'','background-image': _vm.openFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('open')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.openFlag
                  ? require('../../assets/whole/mode5_open.png')
                  : require('../../assets/whole/mode5.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("全开场景")])])]),_c('div',{staticClass:"sList"},[_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.glassFlag?'0':'','background-image': _vm.glassFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('glass')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.glassFlag
                  ? require('../../assets/whole/wholeImg1_open.png')
                  : require('../../assets/whole/wholeImg1.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("雾化玻璃")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.tvFlag?'0':'','background-image': _vm.tvFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('tv')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.tvFlag
                  ? require('../../assets/whole/wholeImg2_open.png')
                  : require('../../assets/whole/wholeImg2.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("电视")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.airFlag?'0':'','background-image': _vm.airFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('air')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.airFlag
                  ? require('../../assets/whole/wholeImg3_open.png')
                  : require('../../assets/whole/wholeImg3.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("空调")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.tableFlag?'0':'','background-image': _vm.tableFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('table')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.tableFlag
                  ? require('../../assets/whole/wholeImg4_open.png')
                  : require('../../assets/whole/wholeImg4.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("台灯")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.cabFlag?'0':'','background-image': _vm.cabFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('cab')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.cabFlag
                  ? require('../../assets/whole/wholeImg5_open.png')
                  : require('../../assets/whole/wholeImg5.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("柜灯")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.bedsideFlag?'0':'','background-image': _vm.bedsideFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('bedside')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.bedsideFlag
                  ? require('../../assets/whole/wholeImg6_open.png')
                  : require('../../assets/whole/wholeImg6.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("床头灯")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.backFlag?'0':'','background-image': _vm.backFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('back')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.backFlag
                  ? require('../../assets/whole/wholeImg7_open.png')
                  : require('../../assets/whole/wholeImg7.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("背景灯")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.ambientFlag?'0':'','background-image': _vm.ambientFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('ambient')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.ambientFlag
                  ? require('../../assets/whole/wholeImg8_open.png')
                  : require('../../assets/whole/wholeImg8.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("氛围灯")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.bathFlag?'0':'','background-image': _vm.bathFlag ? ("url(" + _vm.img + ")") : '' }),on:{"click":function($event){return _vm.change('bath')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.bathFlag
                  ? require('../../assets/whole/wholeImg9_open.png')
                  : require('../../assets/whole/wholeImg9.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("卫浴灯")])]),_c('div',{staticClass:"sLi"},[_c('div',{staticClass:"sImg",style:({ 'border':_vm.bgmFlag?'0':'','background-image': _vm.bgmFlag ? ("url(" + _vm.img + ")") : '',transform:_vm.musicRotate }),on:{"click":function($event){return _vm.change('bgm')}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.bgmFlag
                  ? require('../../assets/whole/wholeImg10_open.png')
                  : require('../../assets/whole/wholeImg10.png')}})]),_c('div',{staticClass:"sName"},[_vm._v("背景音乐")])])])])]),_c('div',{staticClass:"sec"},[_c('div',{staticClass:"secTit"},[_vm._v("亮度调节，一点到位。为你点亮美好生活")]),_c('div',{staticClass:"secBox",on:{"dragstart":function($event){return _vm.dragenter($event)},"dragenter":function($event){return _vm.dragenter($event)},"dragover":function($event){return _vm.dragenter($event)}}},[_c('div',{staticClass:"img1",style:({ filter: _vm.filter }),on:{"dragstart":function($event){return _vm.dragenter($event)},"dragenter":function($event){return _vm.dragenter($event)},"dragover":function($event){return _vm.dragenter($event)}}}),_c('div',{staticClass:"img2",style:({ width: _vm.width, background: _vm.background }),on:{"dragenter":function($event){return _vm.dragenter($event)},"dragover":function($event){return _vm.dragenter($event)}}}),_c('div',{ref:"lis",staticClass:"line",style:({ left: _vm.positionX }),on:{"mousedown":function($event){return _vm.move($event)}}},[_vm._m(0)])]),_vm._m(1),_c('div',{staticClass:"text"},[_vm._v("0%-100%亮度调节。缓亮缓灭，状态记忆。可明可暗，掌控由你。细腻柔和，点亮全新生活。")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/whole/icon.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bar"},[_c('div',{staticClass:"left"},[_vm._v("1%")]),_c('div',{staticClass:"right"},[_vm._v("100%")])])}]

export { render, staticRenderFns }