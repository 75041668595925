import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Vant from 'vant';
import { Icon,Collapse, CollapseItem,DropdownMenu, DropdownItem,Swipe, SwipeItem} from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Swipe);
Vue.use(SwipeItem);

import {  Button,Select,Carousel,CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(Button);
Vue.use(Select);
Vue.use(Carousel);
Vue.use(CarouselItem);

// router.push('/')

// Vue.use(SwipeItem);
// Vue.use(Vant);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

