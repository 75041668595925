import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/introduce_pc/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home
  // },
  {
    path: '/pc/home',
    name: 'home',
    component: Home
  },

  // 产品部分
  {
    path: '/pc/product',
    name: 'Product',
    component: () => import('../views/introduce_pc/product/Product.vue')
  },
  {
    path: '/pc/ProGateway',
    name: 'GateWay',
    component: () => import('../views/introduce_pc/product/Gateway.vue')
  },
  {
    path: '/pc/ProGatewayParam',
    name: 'GatewayParam',
    component: () => import('../views/introduce_pc/product/GatewayParam.vue')
  },
  {
    path: '/pc/ProSwitch',
    name: 'Switch',
    component: () => import('../views/introduce_pc/product/Switch.vue')
  },
  {
    path: '/pc/ProSwitchParam',
    name: 'SwitchParam',
    component: () => import('../views/introduce_pc/product/SwitchParam.vue')
  },
  {
    path: '/pc/ProScene',
    name: 'Scene',
    component: () => import('../views/introduce_pc/product/Scene.vue')
  },
  {
    path: '/pc/ProSceneParam',
    name: 'SceneParam',
    component: () => import('../views/introduce_pc/product/SceneParam.vue')
  },
  {
    path: '/pc/ProCardParam',
    name: 'CardParam',
    component: () => import('../views/introduce_pc/product/CardParam.vue')
  },
  {
    path: '/pc/ProCard',
    name: 'Card',
    component: () => import('../views/introduce_pc/product/Card.vue')
  },
  {
    path: '/pc/ProUsb',
    name: 'Usb',
    component: () => import('../views/introduce_pc/product/Usb.vue')
  },
  {
    path: '/pc/ProUsbParam',
    name: 'UsbParam',
    component: () => import('../views/introduce_pc/product/UsbParam.vue')
  },
  {
    path: '/pc/ProAir',
    name: 'Air',
    component: () => import('../views/introduce_pc/product/Air.vue')
  },
  {
    path: '/pc/ProAirParam',
    name: 'AirParam',
    component: () => import('../views/introduce_pc/product/AirParam.vue')
  },
  {
    path: '/pc/ProCurtain',
    name: 'Curtain',
    component: () => import('../views/introduce_pc/product/Curtain.vue')
  },
  {
    path: '/pc/ProCurtainParam',
    name: 'CurtainParam',
    component: () => import('../views/introduce_pc/product/CurtainParam.vue')
  },
  {
    path: '/pc/ProSingleLight',
    name: 'SingleLight',
    component: () => import('../views/introduce_pc/product/SingleLight.vue')
  },
  {
    path: '/pc/ProSingleSwitch',
    name: 'SingleSwitch',
    component: () => import('../views/introduce_pc/product/SingleSwitch.vue')
  },
  {
    path: '/pc/ProSingleRemote',
    name: 'SingleRemote',
    component: () => import('../views/introduce_pc/product/SingleRemote.vue')
  },
  {
    path: '/pc/ProSingleCheckin',
    name: 'SingleCheckin',
    component: () => import('../views/introduce_pc/product/Checkin.vue')
  },
  {
    path: '/pc/ProSingleRobot',
    name: 'SingleRobot',
    component: () => import('../views/introduce_pc/product/Robot.vue')
  },
  {
    path: '/pc/ProSinglePower',
    name: 'SinglePower',
    component: () => import('../views/introduce_pc/product/SinglePower.vue')
  },

  {
    path: '/pc/ProSingleLed',
    name: 'SingleLed',
    component: () => import('../views/introduce_pc/product/SingleLed.vue')
  },
  {
    path: '/pc/ProSingleRGBW',
    name: 'SingleRGBW',
    component: () => import('../views/introduce_pc/product/SingleRGBW.vue')
  },
  {
    path: '/pc/ProSingleInfrared',
    name: 'SingleInfrared',
    component: () => import('../views/introduce_pc/product/SingleInfrared.vue')
  },
  {
    path: '/pc/ProSingleHifi',
    name: 'SingleHifi',
    component: () => import('../views/introduce_pc/product/SingleHifi.vue')
  },
  {
    path: '/pc/custom',
    name: 'Custom',
    component: () => import('../views/introduce_pc/Custom.vue')
  },
  {
    path: '/pc/demo',
    name: 'Demo',
    component: () => import('../views/introduce_pc/Demo.vue')
  },
  {
    path: '/pc/about',
    name: 'About',
    component: () => import('../views/introduce_pc/About.vue')
  },
  {
    path: '/pc/whole',
    name: 'Whole',
    component: () => import('../views/introduce_pc/Whole.vue')
  },


  // 手机版
  // 首页
  {
    path: '/mobile/home',
    name: 'MobileHome',
    component: () => import('../views/introduce_mobile/MobileHome.vue')
  },
  // 全宅智能
  {
    path: '/mobile/whole',
    name: 'MobileWhole',
    component: () => import('../views/introduce_mobile/MobileWhole.vue')
  },
  // 智能定制
  {
    path: '/mobile/custom',
    name: 'MobileCustom',
    component: () => import('../views/introduce_mobile/MobileCustom.vue')
  },
  // 精选案例
  {
    path: '/mobile/demo',
    name: 'MobileDemo',
    component: () => import('../views/introduce_mobile/MobileDemo.vue')
  },
  // 智能面板
  {
    path: '/mobile/ProPanel',
    name: 'MobilePanel',
    component: () => import('../views/introduce_mobile/MobilePanel.vue')
  },
  // 关于我们
  {
    path: '/mobile/about',
    name: 'MobileAbout',
    component: () => import('../views/introduce_mobile/MobileAbout.vue')
  },
  // 产品中心
  {
    path: '/mobile/ProGateway',
    name: 'MobileGateway',
    component: () => import('../views/introduce_mobile/MobileGateway.vue')
  },
  {
    path: '/mobile/ProGatewayParam',
    name: 'MobileGatewayParam',
    component: () => import('../views/introduce_mobile/MobileGatewayParam.vue')
  },
  {
    path: '/mobile/ProSwitch',
    name: 'MobileSwitch',
    component: () => import('../views/introduce_mobile/MobileSwitch.vue')
  },
  {
    path: '/mobile/ProSwitchParam',
    name: 'MobileSwitchParam',
    component: () => import('../views/introduce_mobile/MobileSwitchParam.vue')
  },
  {
    path: '/mobile/ProScene',
    name: 'MobileScene',
    component: () => import('../views/introduce_mobile/MobileScene.vue')
  },
  {
    path: '/mobile/ProSceneParam',
    name: 'MobileSceneParam',
    component: () => import('../views/introduce_mobile/MobileSceneParam.vue')
  },
  {
    path: '/mobile/ProCard',
    name: 'MobileCard',
    component: () => import('../views/introduce_mobile/MobileCard.vue')
  },
  {
    path: '/mobile/ProCardParam',
    name: 'MobileCardParam',
    component: () => import('../views/introduce_mobile/MobileCardParam.vue')
  },
  {
    path: '/mobile/ProUsb',
    name: 'MobileUsb',
    component: () => import('../views/introduce_mobile/MobileUsb.vue')
  },
  {
    path: '/mobile/ProUsbParam',
    name: 'MobileUsbParam',
    component: () => import('../views/introduce_mobile/MobileUsbParam.vue')
  },
  {
    path: '/mobile/ProAir',
    name: 'MobileAir',
    component: () => import('../views/introduce_mobile/MobileAir.vue')
  },
  {
    path: '/mobile/ProAirParam',
    name: 'MobileAirParam',
    component: () => import('../views/introduce_mobile/MobileAirParam.vue')
  },
  {
    path: '/mobile/ProCurtains',
    name: 'MobileCurtain',
    component: () => import('../views/introduce_mobile/MobileCurtain.vue')
  },
  {
    path: '/mobile/ProCurtain',
    name: 'MobileCurtainOne',
    component: () => import('../views/introduce_mobile/MobileCurtainOne.vue')
  },
  {
    path: '/mobile/ProCurtainParam',
    name: 'MobileCurtainParam',
    component: () => import('../views/introduce_mobile/MobileCurtainParam.vue')
  },

  {
    path: '/mobile/ProSinglePoint',
    name: 'MobileSinglePoint',
    component: () => import('../views/introduce_mobile/MobileSinglePoint.vue')
  },
  {
    path: '/mobile/ProSingleLight',
    name: 'MobileSingleLight',
    component: () => import('../views/introduce_mobile/MobileSingleLight.vue')
  },
  {
    path: '/mobile/ProSingleSwitch',
    name: 'MobileSingleSwitch',
    component: () => import('../views/introduce_mobile/MobileSingleSwitch.vue')
  },
  {
    path: '/mobile/ProSingleRemote',
    name: 'MobileSingleRemote',
    component: () => import('../views/introduce_mobile/MobileSingleRemote.vue')
  },
  {
    path: '/mobile/ProSinglePower',
    name: 'MobileSinglePower',
    component: () => import('../views/introduce_mobile/MobileSinglePower.vue')
  },
  {
    path: '/mobile/ProOther',
    name: 'MobileOther',
    component: () => import('../views/introduce_mobile/MobileOther.vue')
  },
  {
    path: '/mobile/ProSingleLed',
    name: 'MobileLed',
    component: () => import('../views/introduce_mobile/MobileLed.vue')
  },
  {
    path: '/mobile/ProSingleRgbw',
    name: 'MobileRgbw',
    component: () => import('../views/introduce_mobile/MobileRgbw.vue')
  },
  {
    path: '/mobile/ProSingleInfrared',
    name: 'MobileInfrared',
    component: () => import('../views/introduce_mobile/MobileInfrared.vue')
  },
  {
    path: '/mobile/ProSingleHiFi',
    name: 'MobileHiFi',
    component: () => import('../views/introduce_mobile/MobileHiFi.vue')
  },
  {
    path: '/mobile/ProSingleCheckin',
    name: 'MobileCheckin',
    component: () => import('../views/introduce_mobile/MobileCheckin.vue')
  },
  {
    path: '/mobile/ProSingleRobot',
    name: 'MobileRobot',
    component: () => import('../views/introduce_mobile/MobileRobot.vue')
  },
  // {
  //   path: '*',
  //   name:'404',
  //   component:!isPc?() => import('../views/introduce_pc/Home.vue'):() => import('../views/introduce_mobile/MobileHome.vue')
  // },

]


Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
let isPhone = function () {
  let userAgentInfo=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return userAgentInfo;  
}
var isPc=isPhone();

router.beforeEach((to, from, next) => {
  // 判断是否匹配到对应路由
      if (to.matched.length === 0) {
        if(isPc){
          router.push({
            path: '/mobile/home'
          })
          
        }else{
          router.push({
            path: '/pc/home'
          })
        }
        // next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
      }
      next() // 如果匹配到正确跳转
})
export default router

